footer {
    background-color: #1B3058;
    color: #fff;
    /* position: absolute; */
    bottom: 0;
    width: 100%;
    /* height: 300px; */
    border-top: solid 6px #FFB400
}

#footer-copy {
    background-color: #23427F;
    border: none;
    margin: 0;
    padding: 5px;
    font-family: "Libre Baskerville", serif;
}

.site-footer {
    display: flex;
    justify-content: space-around;
    list-style: none;
    gap: 50px;
}

.site-footer ul {
    display: inline-block;
    height: 200px;
}

.site-footer ul div {
    margin-top: 50px;
}


.site-footer ul li {
    list-style: none;
}

.title-footer {
    font-family: "Mulish";
    font-weight: 700;
    font-size: 18px;
    margin-bottom: 20px;
}

.service-style li a{
    color: #FFB400;
    text-decoration: none;
    list-style-position: inside;
}