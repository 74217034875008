.topcorner {
    position: absolute;
    top: 0;
    right: 0;
}

.topcornerFilterMore {
    position:relative;
    top:50;
    left:0;
    float:right;
}

.topcornerFilter {
    position: absolute;
    top: 8vh;
    left: 1.1vh;
    display: flex;
    flex-direction: column;
   
    background-clip: padding-box;
    padding: 10px;
    width: 10px;
}

.header-menu {
    flex: 1;
    background: #F4F6F8;
    padding-right: 50px;

    display: flex;
    justify-content: flex-end;
}

.header-menu img {
    height: 50px;
}

.header-menu ul {
    list-style: none;
    display: flex;
    /* height: 5%; */
}

.header-menu ul li {
    /* margin-left: 6px; */
    display: flex;
    /* align-items: center; */
    color: #333;
    font-weight: 600;
}

.header-menu ul li a {
    color: #fff;
}

.sub-menu {
    flex: 1;
    background: #fff;
    padding: 1% 20%;

    display: flex;
    justify-content: space-between;
}

.sub-menu img {
    height: 100px;
}

.sub-menu ul {
    /* list-style: none; */
    display: flex;
    height: 50%;
}

.sub-menu ul li {
    margin-left: 16px;
    display: flex;
    align-items: center;
}

.sub-menu ul li a {
    text-decoration: none;
    display: inline-block;
    line-height: 20px;
    margin: 10px 0 5px;
    font-style: italic;

}

.sub-menu ul li a.active {
    color: #f68b1f;
    /* Coral */
}

.campo-busca {
    padding-left: 50px;
    /* background-color: black; */
}

.main-navbar {
    /* margin-bottom: 30px; */
    text-align: center;
    padding: 10px 0;
    min-height: 36px;
    position: relative;
    background-color: #1B3058;
    border-top: solid 5px #FFCB00;
}

.main-navbar ul {
    list-style: none;
    display: flex;
    /* justify-content: space-around; */
    padding-left: 150px;

}

.main-navbar ul li {
    margin-left: 16px;
    display: flex;
    align-items: center;
    color: #fff;
    
}

.main-navbar ul li a{
    margin-left: 16px;
    display: flex;
    align-items: center;
    color: #fff;
    text-decoration: none;
}